import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`This  is the documentation section of the AnyMessage API's. These API's will
provide you with the easiest integrations for any of the below listed protocols and/or communication channels.`}</p>
    <ul>
      <li parentName="ul">{`SMS API`}</li>
      <li parentName="ul">{`TTS API`}</li>
      <li parentName="ul">{`Call API`}</li>
      <li parentName="ul">{`Send Message`}</li>
      <li parentName="ul">{`Chat `}</li>
      <li parentName="ul">{`Cloud Storage`}</li>
      <li parentName="ul">{`Qr Code Solutions `}</li>
      <li parentName="ul">{`App Push `}</li>
      <li parentName="ul">{`Shortlink creation and redirect`}</li>
      <li parentName="ul">{`AIT API`}</li>
    </ul>
    <p>{`Please note that some of the above options are not “publicly” available, as the
presently implemented solutions are customer specific integrations. If you like
to use any of the above listed but nonpublic API’s, don’t hesitate to contact us
`}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request&body=Dear%20Anymessage%20Team%2C%20%0AI%20read%20your%20online%20documentation%20and%20have%20the%20following%20questions%20%2F%20requests.%20%0A%0APUT%20YOUR%20QUESTION%20%2F%20REQUEST%20HERE%20%0A%0AThanks%20in%20advance%20and%20have%20a%20nice%20day.%20%0AYOUR_NAME%20%0A"
      }}>{`“here”`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      